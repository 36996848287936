<!-- Vue Component -->
<template>
  <form @submit.prevent="save()">
    <app-loading v-show="loading"></app-loading>
    <div class="form-group">
      <label for>Entidad: *</label>
      <SelectEntity
        v-model="entity_id"
        required
        :disabled="disabled"
      ></SelectEntity>
    </div>
    <div class="form-group">
      <label for>Area: *</label>
      <SelectArea v-model="area_id" :disabled="disabled"></SelectArea>
    </div>
    <div class="form-group">
      <label for="">Punto de Atencion:*</label>
      <SelectStore
        ref="elSelectStore"
        required
        v-model="store_id"
        :disabled="disabled"
      ></SelectStore>
    </div>

    <!-- <div class="form-group" v-if="!client_id" v-show="!disabled"> -->
    <!--   <button class="btn btn-secondary" @click.prevent="$refs.mSetCli.show()"> -->
    <!--     Cargar perfil de cliente -->
    <!--   </button> -->
    <!-- </div> -->
    <div class="form-group">
      <button v-if="!disabled" class="btn btn-primary">Guardar</button>
    </div>
    <div class="mb-0 mt-3 alert alert-success" v-show="visibilitySavedFeedback">
      Guardado
    </div>
  </form>
</template>
<script>
import SelectArea from "../admin-module/areas/Select.vue";
import SelectEntity from "../admin-module/entitys/Select.vue";
// import http from "../../http";
// import FormClient from "../../store-module/clients/Form";
// import SelectClient from "../../store-module/clients/Select";
// import { StoreService } from "../../store-module/service";
import SelectStore from "./stores/Select.vue";
import { GeneralService } from "../general-module/GeneralService.js";

export default {
  components: {
    SelectArea,
    SelectEntity,
    // FormClient,
    // SelectClient,
    SelectStore
  },

  // directives
  // filters

  props: {
    disabled: {
      default: false
    }
  },

  data: () => ({
    entity_id: null,
    area_id: null,
    store_id: null,
    visibilitySavedFeedback: false,
    loading: false
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    this.getProfile();
  },

  methods: {
    async getProfile() {
      this.loading = true;
      let user = await GeneralService.getProfile();
      // this.user = user;
      this.entity_id = user.entity_id;
      this.area_id = user.area_id;
      //this.store_id = user.store_id;
      this.$refs.elSelectStore.setValueFromId(user.store_id);
      this.loading = false;
    },
    async save() {
      this.visibilitySavedFeedback = false;
      await GeneralService.saveProfile({
        entity_id: this.entity_id,
        area_id: this.area_id,
        store_id: this.store_id
      });

      this.visibilitySavedFeedback = true;

      await this.$store.dispatch("getUser");
    }
  }
};
</script>

<style scoped></style>
